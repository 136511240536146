<template>
  <v-container fluid class="ma-4 pr-10">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Movimentação Cadastral</h1>
        <h2 class="secondary--text font-weight-regular">Alteração</h2>
      </v-col>
    </v-row>
    <h2 class="mt-6 mb-2 secondary--text font-weight-regular">
      Pesquisa Beneficiário
    </h2>
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'dependent'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'holder'; results = {}; page = 1; totalPages = 1"
        >
          Titular
        </v-btn>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'holder'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'dependent'; results = {};"
        >
          Dependente
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="formRegister" v-model="isValidForm">
      <v-row class="mt-10 align-center">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="search.financialGroupIds"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroups"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="getContractsByFinancialGroupIdVerifyRhProtegido(search.financialGroupIds, isRHProtegido); verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="search.insuranceCarrierIds"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.financialGroupIds ? true : false"
            @input="verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Contrato</label>
          <v-autocomplete
            v-model="search.contractIds"
            :items="contracts"
            item-value="id"
            :loading="loadingContracts"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="verifyFieldsFilters(); verifyFreeMovement()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="onClickEdit('freeMovement')"
            :disabled="isDisableFreeMovement"
            >
            Movimentação livre
          </v-btn>
        </v-col>
        <v-col class="pt-0 pl-0 pb-6" cols="12" xs="12" sm="1" md="3" lg="2">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                dense
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>Movimentação livre: escolha um contrato que permita movimentação livre</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'CPF do Titular' : 'CPF do Dependente' }}</label>
          <v-text-field
            v-model="search.cpf"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="search.cpf ? [rule.cpf] : []"
            outlined
            required
            color="textPrimary"
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'Nome do Titular' : 'Nome do Dependente' }}</label>
          <v-text-field
            v-model="search.name"
            :rules="search.name ? [rule.name, rule.validateIsAlphanumeric] : []"
            class="mt-2"
            placeholder="Informe o nome"
            maxlength="70"
            outlined
            color="textPrimary"
            required
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Matrícula</label>
          <v-text-field
            maxlength="50"
            v-model="search.registration"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFieldFilters"
            :loading="loadingBeneficiary"
            @click.prevent="filter('filter')"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn color="textPrimary" x-large text @click="clearFields()">
            Limpar campos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <HolderSearchTable
        v-if="search.type === 'holder'"
        :responseData="results"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="edit"
        @setPagination="setPagination"
        @onClickHandler="onClickEdit"
      />
      <DependentSearchTable
        v-else
        :responseData="results"
        :byAcordeon="false"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="edit"
        @setPagination="setPagination"
        @onClickHandler="onClickEdit"
      />
    </v-row>

    <v-footer class="my-10 pr-0">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickCancel()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
        <v-col cols="12" align="center">
          <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
        </v-col>
      </v-row>
    </DynamicDialogInformation>
  </v-container>
</template>
<script>

import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import ContractService from '@/services-http/contract/ContractService';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import HolderSearchTable from '@/components/Beneficiary/Holder/HolderSearchTable.vue';
import DependentSearchTable from '@/components/Beneficiary/Dependent/DependentSearchTable.vue';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';

export default {
  name: 'ManualMovementSearchHolderDependent',
  components: { DynamicDialogInformation, HolderSearchTable, DependentSearchTable },
  data: () => ({
    search: {
      financialGroupIds: null,
      insuranceCarrierIds: null,
      contractIds: null,
      registration: null,
      cpf: null,
      name: null,
      type: 'holder',
    },
    widthModal: 800,
    heightCard: 600,
    page: 1,
    totalPages: 0,
    loadingBeneficiary: false,
    results: {},
    carriers: [],
    financialGroups: [],
    contracts: [],
    insuranceCarriers: [],
    expanded: [],
    isValidForm: true,
    isRHProtegido: false,
    insuranceCarrierIds: '',
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    isDisableFieldFilters: true,
    params: [],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    overlay: false,
    isDisableFreeMovement: true,
  }),

  mixins: [
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
    VerifyRoutesMixin,
    GenericFunctionsMixin,
  ],

  async mounted() {
    this.clearSessionStorage();
    await this.getFinancialGroups();
    await this.getInsuranceCarriers();

    this.verifyFieldsFilters();
    if (this.financialGroups && this.financialGroups.length === 1) {
      this.search.financialGroupIds = this.financialGroups[0].id;
      this.getContractsByFinancialGroupIdVerifyRhProtegido(this.search.financialGroupIds, this.isRHProtegido);
      this.verifyFieldsFilters();
    }
  },

  methods: {
    setPagination(pagination) {
      this.page = pagination.page;
      this.totalPages = pagination.totalPages;
      this.filter('pagination');
    },
    filter(type) {
      if (this.$refs.formRegister.validate()) {
        if (type === 'filter') this.page = 1;
        if (this.search.financialGroupIds !== null || this.search.insuranceCarrierIds !== null || this.search.contractIds !== null || this.search.registration !== null || this.search.cpf !== null || this.search.name !== null) {
          const data = this.verifyParams();

          if (this.search.type === 'holder') {
            this.loadBeneficiary(data);
          } else if (this.search.type === 'dependent') {
            this.loadDependent(data);
          }
        }
      } else {
        this.customizeSnackbarMessage('error', 'Preencha corretamente para prosseguir');
      }
    },
    verifyParams() {
      const cpfUnmasked = this.search.cpf ? this.formatter.removeNonDigit(this.search.cpf) : null;
      const isActive = 'ACTIVE';
      const page = this.page - 1;
      const size = 10;

      const params = new URLSearchParams();
      if (this.search.financialGroupIds) {
        params.append('financialGroupIds', this.search.financialGroupIds);
      }
      if (this.search.insuranceCarrierIds) {
        params.append('insuranceCarrierIds', this.search.insuranceCarrierIds);
      }
      if (this.search.contractIds) {
        params.append('contractIds', this.search.contractIds);
      }
      if (this.search.registration) {
        params.append('registration', this.search.registration);
      }
      if (this.search.cpf) {
        params.append('cpf', cpfUnmasked);
      }
      if (this.search.name) {
        params.append('name', this.search.name);
      }

      if (this.isRHProtegido) {
        params.append('allowMovementByProtectedHR', 'true');
      }

      params.append('insuredSituation', isActive);
      params.append('page', page);
      params.append('size', size);

      return params;
    },
    clearFields() {
      this.$refs.formRegister.reset();
      this.contracts = [];
      this.verifyFieldsFilters();
      this.isDisableFreeMovement = true;
    },
    async onClickEdit(item) {
      this.overlay = true;

      if (this.search.type === 'holder') {
        await this.loadDependents(item)
      }

      await this.requestInsuranceCarrier(item && item.contractId ? item.contractId : this.search.contractIds);
      const queryString = { isRHProtegido: `${this.isRHProtegido}` };
      if (item === 'freeMovement') {
        const planInfo = {
          financialGroupSelected: this.financialGroups.find((financialGroup) => financialGroup.id === this.search.financialGroupIds),
          contractSelected: this.contracts.find((contract) => contract.id === this.search.contractIds),
        };
        sessionStorage.setItem('planInfo', JSON.stringify(planInfo));
        queryString.movementType = this.search.type === 'holder' ? 'UPDATE' : 'UPDATE_DEPENDENT';
        queryString.insuranceCarrierIds = `${this.insuranceCarrierIds}`;
        queryString.beneficiaryType = this.search.type === 'holder' ? 'HOLDER' : 'DEPENDENT';
        queryString.freeMovement = true;
        this.overlay = false;
      } else {
        queryString.id = item.physicalPersonId;
        queryString.movementType = item.isHolder ? 'UPDATE' : 'UPDATE_DEPENDENT';
        queryString.insuranceCarrierIds = `${this.insuranceCarrierIds}`;
        queryString.beneficiaryType = item.isHolder ? 'HOLDER' : 'DEPENDENT';

        this.search.type = item.isHolder ? 'holder' : 'dependent';
        await this.loadBeneficiaryById(item);
      }
      const name = this.search.type === 'holder' ? 'Alteration' : 'DependentAlteration';
      this.redirectRouter(name, queryString);
    },
    async loadBeneficiaryById(item) {
      const queryString = `v2/${item.contractPlanId}/contracted_plan_data`;

      if (item.isHolder) {
        await this.getHolderById(queryString);
      }
      if (item.isDependent) {
        await this.getDependentById(queryString);
      }
    },
    async loadDependents(item) {
      const params = new URLSearchParams();

      if (item.financialGroupId) {
        params.append('financialGroupIds', item.financialGroupId);
      }

      if (item.contractId) {
        params.append('contractIds', item.contractId);
      }

      if (item.subcontractId) {
        params.append('subcontractIds', item.subcontractId);
      }

      item.dependents = [];

      await this.dependentContractedPlanService.DependentToCreateMovementByInsuredId(item.contractPlanId, params).then((response) => {
        if (response && response.data) {
          item.dependents = response.data;
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar os dependentes');
      });
    },
    async getHolderById(queryString) {
      await this.beneficiaryContractedPlanService.FindById(queryString).then((response) => {
        if (response && response.data) {
          const holder = response.data;
          sessionStorage.setItem('holderAlteration', JSON.stringify(holder));
          this.overlay = false;
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar os dados do contrato');
        this.overlay = false;
      });
    },
    async getDependentById(queryString) {
      await this.dependentContractedPlanService.FindById(queryString).then((response) => {
        if (response && response.data) {
          const dependent = response.data;
          sessionStorage.setItem('dependentAlteration', JSON.stringify(dependent));
          this.overlay = false;
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar os dados do contrato');
        this.overlay = false;
      });
    },
    async loadBeneficiary(params) {
      this.loadingBeneficiary = true;
      await this.beneficiaryContractedPlanService.HolderToCreateMovement(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch(() => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    async loadDependent(params) {
      this.loadingBeneficiary = true;
      await this.dependentContractedPlanService.DependentToCreateMovement(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch(() => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    verifyFieldsFilters() {
      if (this.search.financialGroupIds || this.search.insuranceCarrierIds || this.search.contractIds || this.search.registration || this.search.cpf || this.search.name) {
        this.isDisableFieldFilters = false;
      } else {
        this.isDisableFieldFilters = true;
      }
    },
    onClickCancel() {
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    async requestInsuranceCarrier(contract) {
      if (contract) {
        await this.contractService.FindById(contract).then(async (response) => {
          this.insuranceCarrierIds = response.data.carrier_id.id;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    verifyFreeMovement() {
      this.isDisableFreeMovement = this.contracts.filter((contract) => contract.id === this.search.contractIds).some((isFreeMovement) => !isFreeMovement.allow_free_movement);
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.contractService = new ContractService();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.dependentContractedPlanService = new DependentContractedPlanService();
  },
};
</script>
